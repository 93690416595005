import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
function App() {
    return (
        <>
            <Navbar></Navbar>
        </>
    );
}

export default App;
